@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font-family: "DM Sans", sans-serif;
    line-height: 1.5;
    background-color: #f1f3fb;
    padding: 0 0rem;
}

img {
    max-width: 100%;
    display: block;
}


input {
    appearance: none;
    border-radius: 0;
}

.card {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 425px;
    background-color: #FFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(#999, .25);
    padding: 0.75rem;
}

.card-image {
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 75%;
    background-image: url('./logoimg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 10.0%;
    position: relative;
}


.input-group {
    padding-top: 10%;
}


.input1 {
    padding-top: 4%;
    text-align: center;
}

.action {
    padding-block-end: 7%;
    /* padding-left: 35%; */
    text-align: right;

}

.login {
    text-align: center;
    padding-top: 2px;
}

.btn {
    background-color: #0F8F7E;
    color: white;


}