
.form{
    background-color: rgb(19,36,36);
    border-radius: 10px;
    width: 550px;
    margin: 20px auto;
    color:#fff;
    padding: 20px;
    /* height: 600px; */
    
}
h2{
    color: #fff;
}
.Id{
    border-radius: 20px;
}


.form-body{
    text-align:center;
    padding: 20px 10px;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form__input{
    width: 40%;
    height:35px;
    border-radius: 10px;
    border: 2px solid greenyellow;
}


.btn1{
    border:2px solid greenyellow;
    outline: none;
    background:white;
    color:black;
    border-radius: 10px;
    max-width: 120px;
    width: 100%;
    height: 40px;
}
.v{
    padding-top: 25px;
}
.btn1:hover{
    background:rgb(21, 164, 168) ;
    color:white;
   
}